import React, {Component} from 'react';
import axios from "axios";
const {UserGroup,EventNames} = require('../common/commonTypes');

const CHECK_FOR_UPDATE_INTERVAL = (process.env.NODE_ENV === 'development') ? 5000 : 10000;

const UserContext = React.createContext({});

class User extends Component {
    state = {
        group: undefined,
        clientName: '',
        login: '',
        balance: 0.0,
        credit_limit: 0.0,
        lastEventIndex: 0,
        organics_available: false,
        naming_available: false,
        isVisible: document.visibilityState === 'visible',
    };

    constructor(props) {
        super(props);
        this.state.isAuthorized = this.isAuthorized.bind(this);
        this.state.hasPermission = this.hasPermission.bind(this);
        this.state.isInGroup = this.isInGroup.bind(this);
        this.state.notInGroups = this.notInGroups.bind(this);
        this.state.isOrganicsAvailable = this.isOrganicsAvailable.bind(this);
        this.state.isNamingAvailable = this.isNamingAvailable.bind(this);
        this.state.update = this.update.bind(this);
        this.state.logout = this.logout.bind(this);
        this.state.getFavData = this.getFavData.bind(this);
        this.state.setFavData = this.setFavData.bind(this);
        this.state.registerEventListener = this.registerEventListener.bind(this);
        this.state.unregisterEventListener = this.unregisterEventListener.bind(this);

        this.checkForUpdatesTimer = null;
        this.eventListeners = [];

        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    }

    componentDidMount() {
        this.initSession();
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!UserGroup.hasPermission(prevState.group,UserGroup.USER) && UserGroup.hasPermission(this.state.group,UserGroup.USER)) {
            this.enableCheckForUpdatesTimer(true);
        } else if (UserGroup.hasPermission(prevState.group,UserGroup.USER) && !UserGroup.hasPermission(this.state.group,UserGroup.USER)) {
            this.enableCheckForUpdatesTimer(false);
        }
    }

    componentWillUnmount() {
        this.enableCheckForUpdatesTimer(false);
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }

    handleVisibilityChange() {
        const isVisible = document.visibilityState === 'visible';
        if (this.state.isVisible !== isVisible) { // on browser tab activate/deactivate
            this.setState({ isVisible });
            this.enableCheckForUpdatesTimer(isVisible);
        }
    };

    enableCheckForUpdatesTimer(enable) {
        if (enable) {
            if (!this.checkForUpdatesTimer) {
                this.checkForUpdatesTimer = setTimeout(this.onCheckForUpdates.bind(this), CHECK_FOR_UPDATE_INTERVAL);
            }
        } else {
            if (this.checkForUpdatesTimer) {
                clearInterval(this.checkForUpdatesTimer);
                this.checkForUpdatesTimer = null;
            }
        }
    }

    async initSession() {
        let res = await axios.post('/init');
        this.update(res.data);
    }

    async onCheckForUpdates() {
        try {
            let res = await axios.post('/check_for_updates', {lastEventIndex: this.state.lastEventIndex});
            if (res.data) {
                if (res.data.lastEventIndex) {
                    this.setState({lastEventIndex: res.data.lastEventIndex});
                }
                if (Array.isArray(res.data.events)) {
                    res.data.events.forEach(this.applyEvent.bind(this));
                }
            }
        } catch (e) {
            this.clearSession();
        }
        if (this.checkForUpdatesTimer) {
            this.checkForUpdatesTimer = setTimeout(this.onCheckForUpdates.bind(this), CHECK_FOR_UPDATE_INTERVAL);
        }
    }

    applyEvent(event) {
        if (event.name === EventNames.UPDATE_DATA && event.data) {
            this.setState(event.data);
        }
        this.eventListeners.forEach(eventListener => eventListener(event));
    }

    // public methods


    isAuthorized() {
        return this.state.login !== '';
        //return this.group !== UserGroup.GUEST;
    }

    hasPermission(accessLevel) {
        return UserGroup.hasPermission(this.state.group, accessLevel);
    }

    isInGroup(group) {
        return this.state.group === group;
    }

    notInGroups(groups) {
        return UserGroup.notInGroups(this.state.group, groups);
    }

    isOrganicsAvailable() {
        return this.state.organics_available;
    }

    isNamingAvailable() {
        return this.state.naming_available;
    }

    update(data) {
        if (data.fav_data) {
            if (typeof data.fav_data === 'string') {
                try {
                    data.fav_data = JSON.parse(data.fav_data);
                } catch (e) {}
            }
        }
        this.setState(data);
    }

    logout(cb) {
        axios.post('/logout').then(() => {
            if (cb) cb();
        });
        this.clearSession();
    }

    clearSession() {
        this.setState({
            group: UserGroup.GUEST,
            clientName: '',
            login: '',
            balance: 0.0
        });
    }

    getFavData(key,defaultValue=null) {
        if (this.state.fav_data && key in this.state.fav_data) {
            return this.state.fav_data[key];
        }
        return defaultValue;
    }

    setFavData(key,value,saveToServer=false) {
        let fav_data = this.state.fav_data;
        if (!fav_data) fav_data = {};
        fav_data[key] = value;
        this.setState({fav_data: fav_data});
        if (saveToServer) axios.post('/set_fav_data', {fav_data: {[key]: value}});
    }

    //
    registerEventListener(eventListener) {
        this.eventListeners.push(eventListener);
    }

    unregisterEventListener(eventListener) {
        this.eventListeners = this.eventListeners.filter(el => el !== eventListener);
    }

    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export {
    UserGroup,
    UserContext,
    User
}