import React, { Component } from 'react';
import Select from 'react-select';
import {formatClientName} from "../../util/util";

class SelectClient extends Component {
    state = {
        selectedClientID: -1
    };

    onClientSelected(newClient) {
        this.setState({ errors: '', selectedClientID: newClient.value });
        if (this.props.onChange)
            this.props.onChange(newClient.value);
    }

    render() {
        let clients = this.props.clients;
        if (clients && this.props.noneValue) clients = [{id: null, name: this.props.noneValue}, ...this.props.clients];
        let options = clients ? clients.map(client => ({
            value: client.id,
            label: formatClientName(client)
        }) ) : [];

        return (
            <Select
                 className="select"
                 onChange={this.onClientSelected.bind(this)}
                 options={options}
                 isLoading={this.props.isLoading}
                 isDisabled={this.props.isDisabled}
                 isSearchable={true}
                 placeholder={this.props.placeholder ? this.props.placeholder : '-- Выберите клиента --'}
            />
        );
    }
}

export default SelectClient;
