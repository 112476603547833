import axios from "axios";
import { useContext, useEffect, useState } from "react";
import ModalWindow from "../../widgets/ModalWindow";
import EditIcon from "../../components/icons/EditIcon";
import Button from "../../components/UI/Button";
import { UserContext, UserGroup } from "../../context/User";

const LandingModal = ({ name }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [languages, setLanguages] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [dynamicFields, setDynamicFields] = useState({});

    const user = useContext(UserContext);

    const updateDynamicField = (key, event) => {
        setDynamicFields((prev) => ({ ...prev, [key]: event.target.value }));
    };

    const closeModal = () => setIsOpen(false);
    const openModal = () => setIsOpen(true);

    const saveData = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDisabled(true);

        setTimeout(() => {
            console.log(dynamicFields);
            setIsDisabled(false);
            closeModal();
        }, 1000);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.post("/landing_template_data", {
                    landing_id: name,
                });
                setLanguages(data.geo_list);
                setDynamicFields({
                    bonus: data.dynamic_fields?.bonus?.default,
                    spins: data.dynamic_fields?.spins?.default,
                });
            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, [name]);

    if (!user?.hasPermission(UserGroup.DEVELOPER)) {
        return null;
    }

    return (
        <div id="landing-modal-wrapper">
            <button className="edit-btn" onClick={openModal} type="button">
                <EditIcon />
            </button>
            <ModalWindow
                isVisible={isOpen}
                onClose={closeModal}
                anchorContainerID="landing-modal-wrapper"
                closeButton
                className="landing-modal"
            >
                <form onSubmit={saveData}>
                    <h2
                        style={{
                            color: "red",
                            margin: 0,
                            textTransform: "uppercase",
                        }}
                    >
                        В процессе разработки
                    </h2>
                    <h3>{name}</h3>
                    {languages && (
                        <div className="landing-modal-row">
                            <label>Доступные гео:</label>
                            <div className="readOnlyCode">
                                {languages.map((geo) => (
                                    <span key={geo} className="tinyItem">
                                        {geo}
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}

                    {dynamicFields.bonus !== undefined && (
                        <div className="landing-modal-row">
                            <label>Бонус:</label>
                            <input
                                type="text"
                                required
                                value={dynamicFields.bonus}
                                onChange={(e) => updateDynamicField("bonus", e)}
                            />
                        </div>
                    )}

                    {dynamicFields.spins !== undefined && (
                        <div className="landing-modal-row">
                            <label>Spins:</label>
                            <input
                                type="number"
                                required
                                value={dynamicFields.spins}
                                onChange={(e) => updateDynamicField("spins", e)}
                            />
                        </div>
                    )}

                    {(dynamicFields.spins !== undefined ||
                        dynamicFields.bonus !== undefined) && (
                        <div className="tip warning-block">
                            Значения полей изменятся только на данном потоке
                        </div>
                    )}

                    {(dynamicFields.spins !== undefined ||
                        dynamicFields.bonus !== undefined) && (
                        <Button
                            style={{ marginTop: 10 }}
                            title="Сохранить"
                            withoutRounded
                            size="small"
                            type="submit"
                            isLoading={isDisabled}
                        />
                    )}
                </form>
            </ModalWindow>
        </div>
    );
};

export default LandingModal;
