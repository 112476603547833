import React, { useState } from "react";
import axios from "axios";
import "./imageUploader.css";
import CloseIcon from "../icons/CloseIcon";
import Loader from "./Loader";

const ImageUploader = ({ label, fieldName, onChange, imageType }) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setIsLoading(true);
            try {
                const { data } = await axios.post(
                    "/image_upload",
                    {
                        image: file,
                        image_type: imageType,
                    },
                    { headers: { "Content-Type": "multipart/form-data" } }
                );

                onChange(fieldName, data.filename);
            } catch (err) {
                console.log(err);
            }

            setIsLoading(false);
        }
    };

    return (
        <div className="upload-container">
            <div className="upload-select">
                {isLoading ? (
                    <Loader />
                ) : (
                    <label className="upload-label">
                        <input
                            type="file"
                            accept="image/*"
                            multiple={false}
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                        />
                        <div className="upload-content">
                            <span className="icon-plus">
                                <CloseIcon />
                            </span>
                            <div style={{ marginTop: "8px" }}>{label}</div>
                        </div>
                    </label>
                )}
            </div>
        </div>
    );
};

export default ImageUploader;
