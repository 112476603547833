import "./loader.css";

const Loader = ({variant, type}) => (
    <div className={`loader ${variant} ${type}`}>
        <div>
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
);

export default Loader;
