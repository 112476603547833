import React, { Component } from 'react';
//import {Link} from "react-router-dom";
import Link from '../../widgets/Link';
import Pages from '../../widgets/Pages';
import axios from "axios";
import './organics.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import queryString from 'query-string'
import {formatCurrency, formatDateTime, isEmpty} from "../../util/util";
import OrganicsStats from "./OrganicsStats";
import {OrganicsState} from "../../common/commonTypes";
import {useLocation} from "react-router-dom";

class OrganicsList extends Component {
    state = {
        page: 1,
        isLast: false,
        loading: true,
        organics: [],
        organicsToPush: []
    };

    componentDidMount() {
        this.fetchData(this.state.page);
        this.setState({ organicsToPush: [] });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.loading && !this.state.loading) {
            return;
        }
        let params = queryString.parse(this.props.location.search);
        let page = parseInt(params.page ? params.page : 1);
        if (page < 1) page = 1;
        if (page !== prevState.page && !this.state.loading) {
            //console.log(`[QQQ] {componentDidUpdate} prevState.page:${prevState.page} newPage:${page}`);
            this.fetchData(page);
        }
    }

    async fetchData(page) {
        this.setState({loading: true/*, organics: []*/});

        let res = await axios.post('/organicslist', {page: page});

        if (res.data)
            this.setState({...{loading: false, page: page}, ...res.data});
    }

    isOrganicSelectedForPush(organicID) {
        return this.state.organicsToPush.find(dID => dID === organicID);
    }

    onSelectOrganicForPush(organicID) {
        this.setState({organicsToPush:
                this.isOrganicSelectedForPush(organicID)
                ? this.state.organicsToPush.filter(dID => dID !== organicID)
                : [...this.state.organicsToPush, organicID]
        });
    }

    render() {
        //console.log(`[QQQ] {render} this.state.page: ${this.state.page}`,this.props.location.search);
        const {organics,organicsToPush,page,isLast,loading,total_installs} = this.state;
        //console.log('[QQQ]',organics[0]);
        return (
            <div className="page organics">
                <h1>Органика</h1>

                <div className="section">
                    <div className="inRow" style={{justifyContent: 'flex-start'}}>
                        {/*<div><Link to="/neworganic"><button className="common">Создать</button></Link></div>
                        {/*[PUSH]* / !isEmpty(organicsToPush) > 0 &&
                        <div><Link to={{pathname: '/newpush', organics: organicsToPush}}><button className="green inRow">
                            <div className="icon pushPressedIcon"/>
                            <div className="inCol"><div>Пушнуть</div><div className="smallText">выбрано: {organicsToPush.length}</div></div>
                        </button></Link></div>
                        }*/}
                    </div>
                    <div className='inRow rightAlign' style={{width: '320px'}}>
                        <div className='smallerText'>Всего инсталлов:</div>
                        <div><b>{total_installs}</b></div>
                    </div>
                </div>

                <ul>
                {organics.map(organic => (
                    <li key={organic.id}>
                        <div className="info">
                            {organic.name === '' ? <div/> : <div className="id">{`#${organic.id}`}</div>}
                            <div>
                                <div className="date">{formatDateTime(organic.createDate)}</div>
                                {organic.name === '' ? <div className="id-only">{`#${organic.id}`}</div> : <div className="name">{organic.name}</div>}
                            </div>
                            <div className='rightIcons'>{/* [PUSH]  organic.pushSupport &&
                                <button className={'icon ' + (this.isOrganicSelectedForPush(organic.id) ? 'pushPressedIcon' : 'pushIcon')} data-tip="Выбрать для пуша" data-for={'tooltipPush'+organic.id}
                                        onClick={this.onSelectOrganicForPush.bind(this,organic.id)}/>
                            */}</div>
                        </div>
                        <div className="state">
                            <div className={'icon ' + (organic.state === OrganicsState.ACTIVE ? 'activeIcon' : 'inactiveIcon')}
                                 data-tip={(organic.state === OrganicsState.ACTIVE ? 'Активно' : 'Остановлено')} data-for={'tooltip'+organic.id} />
                        </div>
                        <div className="gameName">
                            {organic.gameName}
                        </div>
                        <div className="countries">
                            {organic.countries}
                        </div>
                        <div className="targetLink">
                            {organic.targetLink}
                        </div>
                        <div className="stats">
                            <Link to={`/organicstats/?id=${organic.id}`} data-tip="Статистика" data-for={'tooltip'+organic.id}>
                                <div className={organic.installCount === 0 ? 'zero' : ''}>{organic.installCount}</div>
                                <button className="button-stats"/>
                            </Link>
                        </div>
                        {/*<ReactTooltip id={'tooltipPush'+organic.id} delayShow={1000} type="light" effect="solid" />*/}
                        <ReactTooltip id={'tooltip'+organic.id} delayShow={100} type="light" effect="solid" />
                    </li>
                ))}
                </ul>
                <div className="footer">
                    <div style={{width: '120px'}}/>
                    <Pages to="/organics" enabled={!loading} current={page} isLast={isLast}/>
                    <div style={{width: '120px'}}/>
                </div>
            </div>
        );
    }
}

export default props => <OrganicsList location={useLocation()} {...props}/>;
