import React, { Component } from 'react';
import axios from "axios";
import {UserContext} from "../context/User";
import {getResponseError} from "../util/util";
import {useLocation, useNavigate} from "react-router-dom";

class LoginPage extends Component {
    // Initialize the state
    constructor(props){
        super(props);
        this.state = {
            errors: ""
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    // Fetch the list on first mount
    getUser() {
        return this.context;
    }

    onSubmit(event) {
        event.preventDefault();
        this.doLogin(this.state.login, this.state.password);
    }

    async doLogin(login,password) {
        if (this.busy) return;
        this.busy = true;
        try {
            let res = await axios.post('/login', {login: login, password: password});
            this.getUser().update(res.data);
            this.props.navigate('/');
        } catch(err) {
            this.setState({errors: {common: getResponseError(err)}});
        }
        this.busy = false;
    }

    onFieldChanged(param,e) {
        this.setState({ errors: '', [param]: e.target.value });
    }

    onDevTestLogin(e) {
        e.preventDefault();
        if (process.env.NODE_ENV === 'development') {
            this.doLogin('111', '111');
        }
    }

    render() {
        const { errors } = this.state;

        return (
            <form id="login-form" onSubmit={this.onSubmit} noValidate>
                <div className="form-group">
                    <input id="login" name="login" type="text" required className="form-control" placeholder="Логин" onChange={this.onFieldChanged.bind(this,"login")} />
                    <div className="error-message text_danger collapse" id="loginError"></div>
                </div>
                <div className="form-group pos-rel">
                    <input id="password" name="password" type="password" onChange={this.onFieldChanged.bind(this,"password")} required className="form-control" placeholder="Пароль" />
                    <a href="#" className="pass-view"></a>
                    <div className="error-message collapse text_danger" id="passwordError"></div>
                </div>

                <div className="form-group">
                    <button className="btn w-100">Войти</button>
                    { process.env.NODE_ENV === 'development' &&
                    <div className="form-group">
                        <div style={{padding: '14px 45px'}}><button className='w-100 dev-test' onClick={this.onDevTestLogin.bind(this)}>Тест. вход</button></div>
                    </div>
                    }
                    { errors && errors.common &&
                    <div className="error-message text_danger" id="commonError">{errors.common}</div>
                    }
                </div>


                {/*<div className="page">
                <img className="logo2" src={'/logo.png'} alt="Deeplink Agency"/>

                <form onSubmit={this.onSubmit} noValidate>
                    <ul>
                        <li className="field">
                            <div><label htmlFor="login">Логин</label></div>
                            <div><input id="login" name="login" type="text" onChange={this.onFieldChanged.bind(this,"login")} required/></div>
                        </li>
                        <li className="field">
                            <div><label htmlFor="password">Пароль</label></div>
                            <div><input id="password" name="password" type="password" onChange={this.onFieldChanged.bind(this,"password")} required/></div>
                        </li>
                        <li className="submit">
                            <div>
                                <div><button className="common">Войти</button></div>
                            </div>
                            { process.env.NODE_ENV === 'development' &&
                            <div><button style={{padding: '14px 45px'}} className='dev-test' onClick={this.onDevTestLogin.bind(this)}>Тест. вход</button></div>
                            }
                        </li>
                    </ul>
                    { errors && errors.common &&
                    <div className="error holder"><div className="message">{errors.common}</div></div>
                    }
                </form>
            </div>*/}
            </form>
        );
    }
}
LoginPage.contextType = UserContext;

export default props => <LoginPage navigate={useNavigate()} {...props}/>;