const CheckboxWithLabel = ({
    label,
    isChecked,
    onChange,
    fieldName,
    style = {},
    labelStyle = {},
}) => (
    <div className="filed-block checkbox-with-label" style={style}>
        <label htmlFor={fieldName} style={labelStyle}>
            {label}
        </label>
        <input
            id={fieldName}
            className="form-check-input"
            type="checkbox"
            checked={isChecked}
            onChange={(e) => onChange(fieldName, e.target.checked)}
        />
    </div>
);

export default CheckboxWithLabel;
