import { useEffect } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { useLocation } from "react-router-dom";

const usePerfectScrollbar = () => {
    const location = useLocation();
    useEffect(() => {
        const psArray = [];

        const findAndInstallScrollbar = () => {
            const customSidebars =
                document.querySelectorAll(".perfect-scrollbar");
            customSidebars.forEach((item) => {
                const ps = new PerfectScrollbar(item);
                psArray.push(ps);
            });
        };

        setTimeout(findAndInstallScrollbar, 500);

        return () => {
            psArray.forEach((item) => {
                item.destroy();
            });
        };
    }, [location]);
};

export default usePerfectScrollbar;
