import { useMemo, useState } from "react";
import axios from "axios";
import {
    DATE_SELECT_OPTIONS,
    INIT_COMMENT_PWA,
    MAX_LIMIT_COMMENTS,
    RANGE_RANDOM_LIKE,
} from "../../constants/pwa";
import Comment from "./Comment";
import Button from "../../components/UI/Button";
import LanguagesList from "./LanguagesList";
import { getRandomInt, getResponseErrorsList } from "../../util/util";

const scrollToBottom = () => {
    setTimeout(() => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });
    }, 0);
};

const Comments = ({ data, updateData, languagesList, createErrorMessage }) => {
    const [isShowLanguagesModal, setIsShowLanguageModal] = useState(false);
    const [isLoadingAddCommentBtn, setIsLoadingAddCommentBtn] = useState(false);
    const [isLoadingAddTenCommentsBtn, setIsLoadingAddTenCommentsBtn] =
        useState(false);
    const [activeLng, setActiveLng] = useState(
        data?.comments ? Object.keys(data?.comments)?.[0] || "" : ""
    );

    const generateComment = async ({ lang, index }) => {
        const sendData = {
            lang,
            index,
            seed: data.seed,
        };
        const res = await axios.post("/pwa_new_comment", sendData);
        const date = DATE_SELECT_OPTIONS[index]?.value || "25+";
        return {
            ...INIT_COMMENT_PWA,
            ...res.data,
            like: getRandomInt(RANGE_RANDOM_LIKE.min, RANGE_RANDOM_LIKE.max),
            date,
        };
    };

    const addTenComments = async () => {
        setIsLoadingAddTenCommentsBtn(true);
        try {
            const startIndex = Object.keys(data.comments[activeLng]).length;
            const countNewComments =
                startIndex > MAX_LIMIT_COMMENTS - 10
                    ? MAX_LIMIT_COMMENTS - startIndex
                    : 10;
            const commentsPromises = [];

            for (
                let i = startIndex;
                i < startIndex + countNewComments;
                i += 1
            ) {
                commentsPromises.push(
                    generateComment({ lang: activeLng, index: i })
                );
            }

            const newComments = await Promise.all(commentsPromises);
            const commentsCopy = JSON.parse(JSON.stringify(data.comments));
            const id = Date.now();
            newComments.map((val, index) => {
                commentsCopy[activeLng][id + index * 2] = { ...val };
            });
            updateData("comments", commentsCopy);
            scrollToBottom();
        } catch (err) {
            createErrorMessage(getResponseErrorsList(err));
        }
        setIsLoadingAddTenCommentsBtn(false);
    };

    const addLanguage = async ({ lngFrom, lngTo }) => {
        try {
            const commentsCopy = data?.comments ? { ...data.comments } : {};
            const id = Date.now();
            commentsCopy[lngTo] = {};

            if (!lngFrom) {
                const newComment = await generateComment({
                    lang: lngTo,
                    index: 0,
                });
                commentsCopy[lngTo] = {
                    [id]: { ...newComment },
                };
            } else {
                Object.keys(data.comments[lngFrom]).forEach(
                    (comment, index) => {
                        commentsCopy[lngTo][id + index] = {
                            ...data.comments[lngFrom][comment],
                        };
                    }
                );
            }

            updateData("comments", commentsCopy);
            setIsShowLanguageModal(false);
            setActiveLng(lngTo);
        } catch (err) {
            createErrorMessage(getResponseErrorsList(err));
        }
    };

    const deleteLng = (lng) => {
        const confirmDelete = window.confirm(
            `Вы действительно хотите удалить ${languagesList[lng] || lng}?`
        );

        if (!confirmDelete) return;
        const commentsCopy = { ...data.comments };
        delete commentsCopy[lng];

        updateData("comments", commentsCopy);

        if (activeLng === lng) {
            setTimeout(() => {
                setActiveLng(Object.keys(data.comments)?.[0] || "");
            }, 0);
        }
    };

    const updateComment = (id, comment) => {
        updateData(["comments", activeLng, id], comment);
    };

    const deleteComment = (id) => {
        updateData(["comments", activeLng, id], "", "delete");
    };

    const addComment = async () => {
        setIsLoadingAddCommentBtn(true);
        try {
            const index = Object.keys(data.comments[activeLng]).length;
            const newComment = await generateComment({
                lang: activeLng,
                index,
            });
            updateData(["comments", activeLng, Date.now()], { ...newComment });
            scrollToBottom();
        } catch (err) {
            createErrorMessage(getResponseErrorsList(err));
        }
        setIsLoadingAddCommentBtn(false);
    };

    const content = data?.comments?.[activeLng];

    const { isLimitDone, countAutoResponse } = useMemo(() => {
        let isLimitDone = false;
        let countAutoResponse = 0;

        if (!content) {
            return { isLimitDone, countAutoResponse };
        }
        if (Object.keys(content).length >= MAX_LIMIT_COMMENTS) {
            isLimitDone = true;
        }
        Object.values(content).forEach((comment) => {
            if (comment.answerType === "auto") {
                countAutoResponse += 1;
            }
        });

        return { isLimitDone, countAutoResponse };
    }, [content]);

    return (
        <>
            <LanguagesList
                data={data?.comments}
                addLanguage={addLanguage}
                deleteLng={deleteLng}
                activeLng={activeLng}
                setActiveLng={setActiveLng}
                isShowModal={isShowLanguagesModal}
                setIsShowModal={setIsShowLanguageModal}
                languagesList={languagesList}
            />
            <div className="comments-pwa" id="comments-pwa">
                {content && (
                    <div className="comments-list">
                        {Object.keys(content).map((key) => (
                            <Comment
                                key={key}
                                commentData={content[key]}
                                update={(comment) =>
                                    updateComment(key, comment)
                                }
                                deleteComment={() => deleteComment(key)}
                                createErrorMessage={createErrorMessage}
                                activeLng={activeLng}
                                indexAutoResponse={countAutoResponse}
                                seed={data.seed}
                            />
                        ))}
                        <div className="buttons-block">
                            <Button
                                size="small"
                                title="Добавить комментарий"
                                isLoading={isLoadingAddCommentBtn}
                                isDisabled={isLimitDone}
                                withoutRounded
                                onClick={addComment}
                            />
                            <Button
                                size="small"
                                title="Добавить 10 комментариев"
                                isDisabled={isLimitDone}
                                withoutRounded
                                isLoading={isLoadingAddTenCommentsBtn}
                                onClick={addTenComments}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Comments;
