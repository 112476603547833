import React, { Component } from 'react';
import Select from 'react-select';

class SelectUser extends Component {
    state = {
        selectedUserID: -1
    };

    onUserSelected(newUser) {
        this.setState({ errors: '', selectedUserID: newUser.value });
        if (this.props.onChange)
            this.props.onChange(newUser.value);
    }

    render() {
        let options = this.props.users ? this.props.users.map(user => ({value: user.user_id, label: `${user.user} - ${user.client}`}) ) : [];

        return (
            <Select
                 className="select"
                 onChange={this.onUserSelected.bind(this)}
                 options={options}
                 isLoading={this.props.isLoading}
                 isDisabled={this.props.isDisabled}
                 isSearchable={true}
                 placeholder={'-- Выберите пользователя --'}
            />
        );
    }
}

export default SelectUser;
