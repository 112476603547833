import { useContext } from "react";
import DarkModeIcon from "../icons/DarkModeIcon";
import HamburgerIcon from "../icons/HamburgerIcon";
import LightModeIcon from "../icons/LightModeIcon";
import "./navbar.css";
import { UserContext } from "../../context/User";
import { formatCurrency } from "../../common/commonUtil";
import { Link } from "react-router-dom";
import Button from "../UI/Button";

const Navbar = () => {
    const toggleTheme = () => {
        const themeNow = localStorage.getItem("theme");
        let themeNext;
        if (!themeNow) {
            themeNext = "light";
        } else {
            themeNext = themeNow === "light" ? "dark" : "light";
            document.body.classList.remove(themeNow);
        }

        document.body.classList.add(themeNext);
        localStorage.setItem("theme", themeNext);
    };

    const user = useContext(UserContext);

    const balance = formatCurrency(user.balance, 2, "");

    const openSidebar = () => {
        document.body.classList.add("mobile-sidebar-open");
    };

    return (
        <div className="navbar-wrapper">
            <div className="container">
                <div className="navbar">
                    <button className="toggle-menu" onClick={openSidebar}>
                        <HamburgerIcon />
                    </button>
                    <div className="right-block">
                        <button className="toggle-theme" onClick={toggleTheme}>
                            <LightModeIcon />
                            <DarkModeIcon />
                        </button>
                        <div className="badge">$</div>
                        <p className={'balance' + (user.balance < 0 ? ' red' : '')}>{balance}</p>
                        <Link to="/cabinet/deposit" className="link-to-deposit">
                            <Button
                                title="Пополнить"
                                variant="success"
                                size="big"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
