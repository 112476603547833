import React, { Component } from 'react';
//import {Link} from "react-router-dom";
import Link from '../../widgets/Link';
import Pages from '../../widgets/Pages';
import axios from "axios";
import './push.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import queryString from 'query-string'
import {formatCurrency, formatDateTime, formatPercent, isEmpty} from "../../util/util";
import {useLocation} from "react-router-dom";

class PushList extends Component {
    state = {
        page: 1,
        isLast: false,
        loading: true,
        pushes: []
    };

    componentDidMount() {
        this.fetchData(this.state.page);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.loading && !this.state.loading) {
            return;
        }
        let params = queryString.parse(this.props.location.search);
        let page = parseInt(params.page ? params.page : 1);
        if (page < 1) page = 1;
        if (page !== prevState.page && !this.state.loading) {
            //console.log(`[QQQ] {componentDidUpdate} prevState.page:${prevState.page} newPage:${page}`);
            this.fetchData(page);
        }
    }

    async fetchData(page) {
        this.setState({loading: true/*, pushes: []*/});

        let res = await axios.post('/pushlist', {page: page});

        if (res.data)
            this.setState({...{loading: false, page: page}, ...res.data});
    }

    render() {
        //console.log(`[QQQ] {render} this.state.page: ${this.state.page}`,this.props.location.search);
        const {pushes,page,isLast,loading,total_click_count} = this.state;
        //let i = 0;
        //pushes.map(push => {
        //    console.log(`[QQQ] push#${i++}`,push);
        //});
        return (
            <div className="page pushlist">
                <h1>Пуши</h1>

                <div className="section">
                    <div className="inRow" style={{justifyContent: 'flex-start'}}>
                    </div>
                    <div className='inRow rightAlign' style={{width: '320px'}}>
                        <div className='smallerText'>Всего переходов по пушам:</div>
                        <div><b>{total_click_count}</b></div>
                    </div>
                </div>

                <ul>
                    {pushes.map((push,i) => (
                        <li key={i}>
                            <div className="info">
                                <div className="date">{formatDateTime(push.time_start)}</div>
                                {push.name === '' ? <div className="id-only"></div> :
                                    <div className="name">{push.name}</div>}
                            </div>
                            <div className="message emoji-text">
                                <div className="title">{push.title}</div>
                                <div className="body">{push.message}</div>
                            </div>
                            <div className="stats">
                                <div data-tip="Переходов / отправлено пушей" data-for={'tooltipStats' + i}>
                                    <span className={push.click_count === 0 ? 'zero' : ''}>{push.click_count + ' / ' + push.device_count}</span>
                                </div>
                            </div>
                            <div className="actions">
                                <Link to={{pathname: '/newpush', templatePush: push}}>
                                    <button className="icon pushRepeatIcon"  data-tip="Пушнуть еще раз" data-for={'tooltipRepeat' + i} />
                                </Link>
                            </div>
                            <ReactTooltip id={'tooltipRepeat' + i} delayShow={600} type="light"
                                          effect="solid"/>
                            <ReactTooltip id={'tooltipStats' + i} delayShow={100} type="light" effect="solid"/>
                        </li>
                    ))}
                </ul>

                {!loading && pushes.length === 0 &&
                    <div style={{marginTop: '20px'}}>
                        Чтобы отправить пуш-уведомления, перейдите на <Link to="/">страницу диплинков</Link>,<br />
                        выберите нужные потоки для пушей кнопочками <span className={'icon pushIcon'}/> и нажмите "Пушнуть".
                    </div>
                }

                <div className="footer">
                    <div style={{width: '120px'}}/>
                    <Pages to="/push" enabled={!loading} current={page} isLast={isLast}/>
                    <div style={{width: '120px'}}/>
                </div>
            </div>
        );
    }
}

export default props => <PushList location={useLocation()} {...props}/>;
