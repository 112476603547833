import React, { Component } from 'react';
import axios from "axios";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserContext, UserGroup} from "../../context/User";
import SelectClient from "../../widgets/admin/SelectClient";
import {formatClientName, formatCurrency, formatDateTime, formatPercent, getResponseErrorsList, pick} from "../../util/util";
import './admin.css';
import BasePage from "../base/BasePage";
import {GameReleaseState} from "../../common/commonTypes";
import AdminGameStats from "./ServerSettings";
import Select from 'react-select';
import Button from '../../components/UI/Button';
import { Link } from 'react-router-dom';

const GET_CLIENT_DATA_FIELDS = ['name', 'contact_person', 'telegram', 'admin_notes', 'balance', 'credit_limit',
    'referrer', 'referral_reward_rate', 'createdAt', '_user_organics_available', '_user_naming_available', 'platform_id']; //'fb_cabs_limit',
const SAVE_CLIENT_DATA_FIELDS = ['name', 'contact_person', 'telegram', 'admin_notes', 'credit_limit',
    '_user_organics_available', '_user_naming_available', 'platform_id'];

class EditClientSettings extends BasePage {

    state = {
        loading: true,
        loadingClient: false,
        executing: false,
        selectedClientID: -1,
    };

    componentDidMount() {
        this.fetchData();
    }

    getUser() {
        return this.context;
    }

    isAdmin() {
        return this.getUser().hasPermission(UserGroup.ADMIN);
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/getdata', {data: ['clients','platforms']});

        if (res.data)
            this.setState({loading: false, clients: res.data.clients,
                platforms: res.data.platforms, depositTypes: res.data.deposit_types});
    }

    async fetchClientData(clientID) {
        if (clientID === -1) {
            this.setState({gamePerm: []});
            return;
        }

        this.setState({loadingClient: true});

        try {
            let res = await axios.post('/getclientdata', {client_id: clientID, data: GET_CLIENT_DATA_FIELDS});
            if (res.data)
                this.setState({...{loadingClient: false, clientData: true}, ...res.data});
        } catch (err) {
            this.setState({loadingClient: false, errors: getResponseErrorsList(err)});
        }
    }

    onClientSelected(clientID) {
        this.setState({ errors: {}, selectedClientID: clientID });
        this.fetchClientData(clientID);
    }

    async onSubmit(event) {
        event.preventDefault();

        if (!this.busy) {
            try {
                this.busy = true;
                this.setState({executing: true});
                await axios.post('/setclientdata', { ...{client_id: this.state.selectedClientID}, ...pick(this.state, SAVE_CLIENT_DATA_FIELDS) });
                this.setState({executing: false, execMsg: 'Сохранено!', errors: {}});
                setTimeout(this.setState.bind(this,{execMsg: null}), 1000);
            } catch (err) {
                this.setState({executing: false, errors: getResponseErrorsList(err)});
            }
            this.busy = false;
        }
    }

    renderPage() {
        const { loading, executing, loadingClient, clients, platforms, clientData, selectedClientID} = this.state;

        const inputField = (fieldName,description,tip,optional=true) => (
            <li className="field">
                <div><label htmlFor={fieldName}>{description}</label>
                    <div className={"tip"}>{tip}</div>
                </div>
                <div><input id={fieldName} name={fieldName} type="text" onChange={this.onFieldChanged.bind(this,fieldName)} required={!optional} value={this.state[fieldName]}/>
                    {this.renderError(fieldName)}
                </div>
            </li>
        );

        const selectField = (fieldName, description, tip, dataValues) => (
            <li className="field">
                <div><label htmlFor={fieldName}>{description}</label>
                    <div className={"tip"}>{tip}</div>
                </div>
                <div>
                <select
                className='big-select'
                value={this.state[fieldName]}
                onChange={this.onFieldChanged.bind(this,fieldName)}>
                    {dataValues.map(v => <option key={v.id} value={v.id}>{v.name}</option> )}
                </select>
                    {this.renderError(fieldName)}
                </div>
            </li>
        );

        const roField = (description, tip, value, valueStyle) => (
            <li className="field smaller">
                <div><label className='smallerText'>{description}</label>
                    <div className={"tip"}>{tip}</div>
                </div>
                <div className={(valueStyle && 'className' in valueStyle) ? valueStyle.className : ''}
                     style={(valueStyle && !('className' in valueStyle)) ? valueStyle : {}}>{value}</div>
            </li>
        );

        const checkboxField = (fieldName,description) => {
            let value = this.state[fieldName];
            return (<li className="inCol" key={fieldName}>
                <div className="inRow">
                    <input 
                    id={fieldName} 
                    className='form-check-input' 
                    name={fieldName} type="checkbox"
                    onChange={this.onFieldChanged.bind(this)}
                    checked={value}/>
                    <label htmlFor={fieldName} className='clickable'>{description}</label>
                </div>
                {this.renderError(fieldName)}
            </li>);
        };

        return (
            <RestrictedArea allowedTo={UserGroup.MODERATOR}>
                <div className="page edit-new-client">
                    <h1>Управление клиентами</h1>
                    <div className='edit-new-client-header'>
                        <div>
                            <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
                                <Link to='/admin/newclient' >
                                    <Button title="+ Новый клиент" withoutRounded />
                                </Link>
                            </RestrictedArea>
                        </div>
               
                        <SelectClient
                            clients={clients}
                            onChange={this.onClientSelected.bind(this)}
                            isLoading={loading}
                            isDisabled={loadingClient}
                        />
                        <div/>
                    </div>
                    <div className="center-section" style={{maxWidth: 500, marginTop: 15}}>
                        { !loading && selectedClientID !== -1 && clientData &&
                        <div className='card' style={{marginTop: 15}}>
                            <form onSubmit={this.onSubmit.bind(this)} className={loadingClient ? 'disabled' : ''}>
                                <h2>{formatClientName(this.state)}: </h2>
                                <ul>
                                    {inputField("name","Название клиента", "Название компании или просто имя клиента")}
                                    {inputField("contact_person","Имя представителя","Если это не указано в названии клиента")}
                                    {inputField("telegram","Telegram акаунт")}
                                    {inputField("admin_notes","Заметка для админа", "Необязательно. Видно только администратору")}
                                    {inputField("credit_limit","Кредитный лимит, $")}

                                    {selectField("platform_id","Платформа", '', platforms)}

                                    {checkboxField("_user_organics_available","Доступ к органике")}
                                    {checkboxField("_user_naming_available","Доступ к неймингу")}

                                    {roField('Регистрация', '', formatDateTime(this.state.createdAt))}

                                    {roField('Текущий баланс', '', formatCurrency(this.state.balance), {fontWeight:'bold'})}
                                    {/*roField('Оборот', '', formatCurrency(this.state.total_turnover))*/}

                                    {roField('Реферер', '', this.state.referrer ? formatClientName(this.state.referrer) : '-')}
                                    {roField('Награда за рефералов', '', formatPercent(this.state.referral_reward_rate,1))}

                                    <li className="submit">
                                        <div>
                                            <div>
                                                <Button 
                                                type='submit' 
                                                withoutRounded
                                                title='Сохранить'
                                                isLoading={executing}
                                                isDisabled={loadingClient}/>
                                            </div>
                                            {this.renderRestErrors()}
                                            {this.state.execMsg &&
                                                <div className="tinyTip holder">{this.state.execMsg}</div>
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                        }

                    </div>

                </div>
            </RestrictedArea>
        );
    }
}
EditClientSettings.contextType = UserContext;


export default EditClientSettings;
