import React, { useEffect, useState } from "react";
import Tabs from "../../components/UI/Tabs";
import Description from "./Description";
import "./style.css";
import Button from "../../components/UI/Button";
import Comments from "./Comments";
import Loader from "../../components/UI/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import InputWithLabel from "../../components/UI/InputWithLabel";
import Settings from "./Settings";
import { StoreID } from "../../common/commonTypes";
import axios from "axios";
import { INIT_DATA, LANGUAGES } from "../../constants/pwa";
import { getResponseErrorsList } from "../../util/util";
import { useBlocker } from "../../hooks/useBlocker";

const validationFormData = (data) => {
    const errors = [];
    if (!data.name) {
        errors.push({
            type: "filed",
            msg: "Поле 'Название' является обязательным.",
        });
    }

    if (errors.length) {
        return { response: { data: { errors } } };
    }
    return null;
};

const CreateEditPwa = ({ isEditMode }) => {
    const [data, setData] = useState(isEditMode ? null : INIT_DATA);
    const [isDisabledBtn, setIsDisabledBtn] = useState(false);
    const [languagesList, setLanguagesList] = useState(null);
    const [templates, setTemplates] = useState(null);
    const [templatesDesc, setTemplatesDesc] = useState(null);
    const [activeTab, setActiveTab] = useState("Описание");
    const [errorData, setErrorData] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);

    const updateData = (key, value, type) => {
        setLastUpdated(Date.now());
        setData((prev) => {
            if (!Array.isArray(key)) {
                return { ...prev, [key]: value };
            }

            const newData = { ...prev };
            let temp = newData;

            for (let i = 0; i < key.length - 1; i++) {
                const keyItem = key[i];
                temp[keyItem] = { ...temp[keyItem] };
                temp = temp[keyItem];
            }

            if (type === "delete") {
                delete temp[key[key.length - 1]];
            } else {
                temp[key[key.length - 1]] = value;
            }

            return newData;
        });
    };

    const location = useLocation();
    const navigate = useNavigate();

    const createErrorMessage = (value) => {
        setErrorData(value);
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });

        setTimeout(() => {
            setErrorData(null);
        }, 3000);
    };

    const onSubmit = async (callback) => {
        setLastUpdated(null);
        try {
            const errorMessage = validationFormData(data);

            if (errorMessage) {
                throw errorMessage;
            }
            const sendData = {};
            const dataCopy = { ...data };

            sendData.name = dataCopy.name;
            delete dataCopy.name;

            if (isEditMode) {
                sendData.app_id = dataCopy.id;
                delete dataCopy.id;
            } else {
                sendData.appType = StoreID.PWA;
            }

            sendData.store_info = JSON.stringify(dataCopy);
            setIsDisabledBtn(true);
            if (!isEditMode) {
                await axios.post("/newapp", sendData);
            } else {
                await axios.post("/updateapp", sendData);
            }

            if (callback) {
                setIsDisabledBtn(false);
                return callback();
            }

            navigate("/apps");
        } catch (err) {
            createErrorMessage(getResponseErrorsList(err));
        }

        setIsDisabledBtn(false);
    };

    const tabItems = [
        {
            label: "Описание",
            children: (
                <Description
                    data={data}
                    updateData={updateData}
                    languagesList={languagesList}
                    templatesDesc={templatesDesc}
                    createErrorMessage={createErrorMessage}
                />
            ),
        },
        {
            label: "Комментарии",
            children: (
                <Comments
                    data={data}
                    updateData={updateData}
                    languagesList={languagesList}
                    createErrorMessage={createErrorMessage}
                />
            ),
        },

        {
            label: "Настройки",
            children: (
                <Settings
                    data={data}
                    updateData={updateData}
                    templates={templates}
                />
            ),
        },
    ];

    useEffect(() => {
        const getLanguages = async () => {
            try {
                const { data } = await axios.post("/app_allowed_languages");
                const languages = {};

                data.forEach((lang) => {
                    if (LANGUAGES[lang]) {
                        languages[lang] = LANGUAGES[lang];
                    }
                });
                setLanguagesList(languages);
            } catch (err) {
                createErrorMessage(getResponseErrorsList(err));
            }
        };

        const getTemplates = async () => {
            try {
                const {
                    data: { pwa_templates },
                } = await axios.post("/newapp_data");
                setTemplates(
                    pwa_templates.map((name) => ({
                        label: name,
                        value: name,
                    }))
                );
            } catch (err) {
                createErrorMessage(getResponseErrorsList(err));
            }
        };

        const getTemplatesDesc = async () => {
            try {
                const { data } = await axios.post(
                    "/pwa_auto_description_templates"
                );
                setTemplatesDesc(
                    data.map(({ template, tip }) => ({
                        label: template,
                        value: template,
                        tip,
                    }))
                );
            } catch (err) {
                createErrorMessage(getResponseErrorsList(err));
            }
        };

        const getPwaData = async () => {
            try {
                const { id } = queryString.parse(location.search);
                const {
                    data: { app, pwa_templates },
                } = await axios.post("/editapp_data", {
                    app_id: id,
                });

                let pwa = {
                    id: app.id,
                    name: app.name,
                };

                if (app.store_info) {
                    pwa = { ...pwa, ...JSON.parse(app.store_info) };
                }

                if (!pwa.seed) {
                    pwa.seed = Date.now();
                }

                setTemplates(
                    pwa_templates.map((name) => ({
                        label: name,
                        value: name,
                    }))
                );

                setData(pwa);
            } catch (err) {
                console.log(err);
            }
        };

        if (isEditMode) {
            getPwaData();
        } else {
            getTemplates();
            setData(INIT_DATA);
        }
        getLanguages();
        getTemplatesDesc();
    }, []);

    // const blocker = async ({ retry }) => {      TEMP COMMIT
    //     const userConfirmed = window.confirm(
    //         "Вы хотите сохранить изменения перед переходом?"
    //     );
    //     if (userConfirmed) {
    //         await onSubmit(retry);
    //     } else {
    //         setLastUpdated(null);
    //         retry();
    //     }
    // };

    // useBlocker(blocker, !!lastUpdated);

    const changeActiveTab = (newTab) => {
        // if (!lastUpdated) {    TEMP COMMIT
        //     setActiveTab(newTab);
        //     return;
        // }

        // if (lastUpdated < Date.now() - 1000 * 40) {
        //     blocker({ retry: () => setActiveTab(newTab) });
        //     return;
        // }

        setActiveTab(newTab);
    };

    if (!data) {
        return <Loader />;
    }

    return (
        <div className="page create-edit-pwa" id="create-edit-pwa">
            <h1>{isEditMode ? "Изменить" : "Создать"} PWA</h1>
            <div className="card">
                <div className="four-fields">
                    <InputWithLabel
                        label="Название в сервисе"
                        value={data.name}
                        fieldName="name"
                        onChange={updateData}
                    />
                </div>
                <Tabs
                    items={tabItems}
                    setActiveTab={changeActiveTab}
                    activeTab={activeTab}
                />
                <div className="footer-block filed-block">
                    <Button
                        withoutRounded
                        title="Сохранить"
                        onClick={() => onSubmit()}
                        isLoading={isDisabledBtn}
                    />
                    {errorData && (
                        <div className="error-block">
                            {Object.values(errorData).map((err) => err)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CreateEditPwa;
