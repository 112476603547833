import { Link } from "react-router-dom";
import Button from "../../../components/UI/Button";
import PWAItem from "./PWAItem";
import { useEffect, useState } from "react";
import Pages from "../../../widgets/Pages";
import axios from "axios";
import { StoreID } from "../../../common/commonTypes";

const PWAList = ({ userId }) => {
    const [pwaList, setPwaList] = useState(null);
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setPwaList(null);

            try {
                const sendData = {
                    page,
                    appType: StoreID.PWA,
                };

                if (userId) {
                    sendData.user_id = userId;
                }

                const { data } = await axios.post("/applist", sendData);

                setPwaList(data.apps);
                setIsLastPage(data.isLast);
            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, [page, userId]);

    const handlerChangePage = (nextPage) => {
        setPage(nextPage);
    };

    return (
        <div className="pwa-block">
            <Link to={`/newpwa`} className="new-pwa-link">
                <Button title="Создать" withoutRounded />
            </Link>
            <div className="pwa-list">
                {pwaList?.map((item) => (
                    <PWAItem key={item.id} pwaData={item} />
                ))}
            </div>
            <div className="footer">
                <Pages
                    style={{ margin: "0 auto", width: "auto" }}
                    handler={handlerChangePage}
                    current={page}
                    isLast={isLastPage}
                />
            </div>
        </div>
    );
};

export default PWAList;
