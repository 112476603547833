const ClueIcon = () => (
    <svg
        fill="#000000"
        viewBox="0 0 32 32"
        id="Outlined"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
            <g id="Fill">
                <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"></path>
                <path d="M18,7H14a3,3,0,0,0-3,3v2h2V10a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v1.17a1,1,0,0,1-.29.71l-2.83,2.83A3,3,0,0,0,15,16.83V20h2V16.83a1,1,0,0,1,.29-.71l2.83-2.83A3,3,0,0,0,21,11.17V10A3,3,0,0,0,18,7Z"></path>{" "}
                <circle cx="16" cy="24" r="2"></circle>
            </g>
        </g>
    </svg>
);

export default ClueIcon;
