import { useContext } from "react";
import SelectWithLabel from "../../components/UI/SelectWithLabel";
import IphoneSwitch from "../../components/UI/IphoneSwitch";
import Loader from "../../components/UI/Loader";
import {
    BUTTONS_TYPE,
    CATEGORIES_PWA,
    REDIRECTS_OPTIONS,
} from "../../constants/pwa";
import { UserContext, UserGroup } from "../../context/User";

const Settings = ({ data, updateData, templates }) => {
    const user = useContext(UserContext);
    if (!templates) return <Loader />;

    return (
        <div className="settings-pwa">
            {user?.hasPermission(UserGroup.DEVELOPER) && (
                <div className="four-fields">
                    <SelectWithLabel
                        options={templates}
                        value={data.template}
                        label="Шаблон"
                        fieldName="template"
                        onChange={updateData}
                    />
                </div>
            )}
            <div className="four-fields">
                <SelectWithLabel
                    options={BUTTONS_TYPE}
                    value={data.installBtnVersion}
                    label="Тип кнопки"
                    fieldName="installBtnVersion"
                    onChange={updateData}
                />
            </div>
            <div className="four-fields">
                <SelectWithLabel
                    options={REDIRECTS_OPTIONS}
                    value={data.redirectVersion}
                    label="Тип редиректа"
                    fieldName="redirectVersion"
                    onChange={updateData}
                />
            </div>
            <div className="tip" style={{ marginTop: "2px" }}>
                {REDIRECTS_OPTIONS[data.redirectVersion - 1]?.desc}
            </div>
            <div className="four-fields">
                <SelectWithLabel
                    label="Категория в Store"
                    value={data.gpCategory}
                    fieldName="gpCategory"
                    onChange={updateData}
                    options={CATEGORIES_PWA}
                />
            </div>

            <div className="filed-block label-with-checkbox">
                <label>Показывать верхнее меню</label>
                <IphoneSwitch
                    isChecked={data.showHeaderMenu}
                    fieldName="showHeaderMenu"
                    onChange={updateData}
                />
            </div>
        </div>
    );
};

export default Settings;
