import Textarea from "./Textarea";

const TextareaWithLabel = ({
    label,
    value,
    onChange,
    fieldName,
    disabled,
    minHeight = 60,
}) => (
    <div className="filed-block">
        <label>{label}</label>
        <Textarea
            style={{ minHeight }}
            value={value || ""}
            disabled={disabled}
            onChange={(e) => onChange(fieldName, e.target.value)}
        />
    </div>
);

export default TextareaWithLabel;
