import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { UserContext, UserGroup } from "../context/User";

const UserSelect = ({ selectedUserId, setSelectedUserId }) => {
    const [users, setUsers] = useState(null);
    const user = useContext(UserContext);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const { data } = await axios.post("/getdata", {
                    data: ["users"],
                });

                const usersList = [{ value: 0, label: "-- Мои --" }];

                data.users.forEach((user) => {
                    usersList.push({
                        value: user.user_id,
                        label: `${user.user}-${user.client}`,
                    });
                });
                setUsers(usersList);
            } catch (err) {
                console.log(err);
            }
        };

        if (
            user?.hasPermission(UserGroup.PLATFORM_ADMIN) ||
            user?.hasPermission(UserGroup.MODERATOR)
        ) {
            getUsers();
        }
    }, [user]);

    if (!users) return null;

    return (
        <div className="user-select">
            <Select
                value={users.find(({ value }) => value === selectedUserId)}
                className="select"
                options={users}
                onChange={(val) => setSelectedUserId(val.value)}
            />
        </div>
    );
};

export default UserSelect;
