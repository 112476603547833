import { formatDateTime } from "../../common/commonUtil";
import CopyIcon from "../../components/icons/CopyIcon";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { StoreID } from "../../common/commonTypes";
import { Tooltip as ReactTooltip } from "react-tooltip";

const RouteItemContent = ({
    id,
    targetLink,
    domains,
    geo,
    wideLinkDesign,
    games,
    isNamingOn,
    naming,
    routeLink,
    createdAt,
    appType,
    onCopy,
}) => (
    <div
        className={`route-item-content ${
            wideLinkDesign ? "wideLinkDesign" : ""
        } ${appType === StoreID.UNIVERSAL ? "universal-type" : ""}`}
    >
        {createdAt && (
            <div className="route-item-date">{formatDateTime(createdAt)}</div>
        )}
        {wideLinkDesign && (
            <div className="domains">
                <div>
                    {domains?.map((domain) => (
                        <div
                            key={domain}
                            className={"tinyItem"}
                            style={{ display: "inline-block" }}
                        >
                            <i>{domain}</i>
                        </div>
                    ))}
                    {geo &&
                        geo.split(",").map((g) => (
                            <div
                                key={g}
                                className={"tinyItem"}
                                style={{ display: "inline-block" }}
                            >
                                {g}
                            </div>
                        ))}
                </div>
                <div>
                    {appType !== StoreID.UNIVERSAL &&
                        games?.map((game) => (
                            <div
                                key={game.name}
                                className={"tinyItem"}
                                style={{ display: "inline-block" }}
                            >
                                <span
                                    className={`icon tiny apptype${game.appType}`}
                                />
                                <span>
                                    {game.name}
                                    <span className="gray">
                                        {game.weight < 1.0
                                            ? " (" +
                                              Math.round(game.weight * 10000) /
                                                  100 +
                                              "%)"
                                            : ""}
                                    </span>
                                </span>
                            </div>
                        ))}
                </div>
            </div>
        )}
        <div className="wide-links-cell">
            <div className="deep-link">
                {isNamingOn && !naming && !wideLinkDesign ? (
                    <div className="tip naming-not-support">
                        Нейминг не поддерживается
                    </div>
                ) : (
                    <div className="deep-link-block">
                        <CopyToClipboard text={routeLink} onCopy={onCopy}>
                            <button
                                type="button"
                                data-tooltip-content="Скопировать"
                                data-tooltip-id={"tooltip" + id}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <CopyIcon />
                            </button>
                        </CopyToClipboard>
                        <div style={{ margin: "2px" }} className="break-all">
                            {routeLink}
                        </div>
                    </div>
                )}
            </div>

            {wideLinkDesign && (
                <>
                    {appType === StoreID.UNIVERSAL ? (
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                            }}
                        >
                            {games?.map((game) => (
                                <div key={game.name} className={"tinyItem tinyItemUniversal"}>
                                    <span className={`icon tiny apptype${ game.routeOSType !== StoreID.UNDEFINED 
                                        ? game.routeOSType: game.appType }`}/>
                                    <span>
                                        <span className="gray">
                                            {game.weight < 1.0 ? " (" + Math.round(  game.weight * 10000 ) /  100 +  "%)"  : ""}
                                        </span>
                                    </span>
                                    <span style={{maxWidth: "none", paddingLeft: "3px"}} className="break-all">
                                        {game.landing ? <span className='gray'>{game.landing} → </span> : null}
                                        {game.appType !== StoreID.UNIVERSAL ?
                                            <span className={`icon tiny apptype${game.appType }`} /> : null}
                                        {game.internalLanding ? `${game.internalLanding} → ` : null}
                                        {game.target}
                                    </span>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="target-link break-all">
                            {targetLink}
                        </div>
                    )}
                </>
            )}
            <ReactTooltip id={"tooltip" + id} type="light" effect="solid" />
        </div>
        {!wideLinkDesign && (
            <div className="wide-links-cell target-link break-all">
                {targetLink}
            </div>
        )}
    </div>
);

export default RouteItemContent;
