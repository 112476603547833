import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LoginPage from "./LoginPage";
import useScript from '../hooks/useScript';

const style = `
*, :before, :after {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font: 400 1.4rem/1.8rem 'Inter', sans-serif;
    color: #fff;
    background: #140c0a;
}

body.show {
    overflow: hidden;
}

body.show .wrap {
    height: 100vh;
}
`

const StartPage = () => {
    useScript('/js/jquery-3.6.0.min.js');
    useScript('/js/sp.js');

    return (
        <HelmetProvider>
        <div id="start-page">
            <Helmet>
                <style>{style}</style>
            </Helmet>
            <div className="wrap">
                <div className="preview">
                    <header className="header">
                        <div className="container d-flex">
                            <a href="/" className="logo">
                                <img src="/sp_img/logo.png" alt="" className="logo__image" />
                                <span className="logo__text">Deeplink Agency</span>
                            </a>
                            <nav className="nav_1" id="nav">
                                <ul className="nav-list">
                                    {/*<li className="nav-list__item"><a href="#" className="nav-list__link">Услуги</a></li>*/}
                                    <li className="nav-list__item"><a href="https://t.me/da_apps_support" className="nav-list__link" target="_blank" rel="noopener noreferrer">Контакты</a></li>
                                    <li className="nav-list__item dropdown">
                                        <a id="cabinet-menu" href="#" className="nav-list__link nav-list__link_btn" data-bs-toggle="dropdown">Личный Кабинет</a>
                                        <div id="cabinet-menu-container" className="dropdown-menu noclose">
                                            <LoginPage/>
                                        </div>
                                    </li>
                                </ul>
                                <a href="#" className="close-menu"></a>
                            </nav>
                            <a href="#" className="btn-menu">
                                <span className="btn-menu__line"></span>
                                <span className="btn-menu__line"></span>
                                <span className="btn-menu__line"></span>
                            </a>
                        </div>
                    </header>
                    <div className="container">
                        <div className="preview__text">
                            <h1 className="title_size-xl preview__title">DA <span className="text_primary">APPS</span></h1>
                            <p>Автоматизированная платформа для работы <br className="d-none d-md-block" />с мобильными приложениями</p>
                            <div className="preview__btn">
                                <a href="https://t.me/da_apps_support"  target="_blank" rel="noopener noreferrer" className="btn">
                                    Связаться с нами
                                    <span className="icon-arrow anim-items anim__showR"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="preview__image">
                        <img src="/sp_img/preview.png" alt="" className="img-fluid" />
                        <div className="preview__slot anim-items anim__showOpacity">
                            <img src="/sp_img/slot.png" alt="" className="img-fluid" />
                        </div>
                        <div className="preview__coin-1 anim-items anim__showOpacity"><img src="/sp_img/coin-1.png" alt="" className="img-fluid" /></div>
                        <div className="preview__coin-2 anim-items anim__showOpacity"><img src="/sp_img/coin-2.png" alt="" className="img-fluid" /></div>
                        <div className="preview__coin-3 anim-items anim__showOpacity"><img src="/sp_img/coin-3.png" alt="" className="img-fluid" /></div>
                    </div>
                </div>
                <div className="advantages">
                    <div className="advantages__image anim-items anim__showL"><img src="/sp_img/advantages-image.png" alt="" className="img-fluid" /></div>
                    <div className="container">
                        <div className="d-flex">
                            <div className="advantages__text anim-items anim__showR">
                                <div className="advantages__headeing">
                                    <div className="title_size-sm">
                                        УНИКАЛЬНЫЙ ПЕРЕНОС АУДИТОРИИ </div>
                                    <p className="text_dark">Мы используем собранную базу самых качественных игроков для переноса событий из прошлых приложений</p>
                                </div>
                                <div className="advantages-item d-flex align-items-center">
                                    <div className="advantages-item__icon"><img src="/sp_img/advantages-1.png" alt="" className="img-fluid" /></div>
                                    <div className="advantages-item__text">
                                        <div className="advantages-item__title">Повышенный траст</div>
                                        <p>Лёгкость запуска с Facebook</p>
                                    </div>
                                </div>
                                <div className="advantages-item d-flex align-items-center">
                                    <div className="advantages-item__icon"><img src="/sp_img/advantages-2.png" alt="" className="img-fluid" /></div>
                                    <div className="advantages-item__text">
                                        <div className="advantages-item__title">Качество игроков</div>
                                        <p>Улучшение показателей окупаемости трафика (по данным рекламодателя)</p>
                                    </div>
                                </div>
                                <div className="advantages-item d-flex align-items-center">
                                    <div className="advantages-item__icon"><img src="/sp_img/advantages-3.png" alt="" className="img-fluid" /></div>
                                    <div className="advantages-item__text">
                                        <div className="advantages-item__title">Скорость</div>
                                        <p>Моментальная возможность залива приложения с оптимизацией за Регу/Деп</p>
                                    </div>
                                </div>
                                <div className="advantages-item d-flex align-items-center">
                                    <div className="advantages-item__icon"><img src="/sp_img/advantages-4.png" alt="" className="img-fluid" /></div>
                                    <div className="advantages-item__text">
                                        <div className="advantages-item__title">Самый высокий показатель CR</div>
                                        <p>ТОП показатели из клик2инстал, инстал2рег, рег2деп</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about">
                    <div className="about__image anim-items anim__showOpacity">
                        <img src="/sp_img/about.jpg" alt="" className="img-fluid" />
                        <img src="/sp_img/about-img-1.png" alt="" className="img-fluid about__img-1" />
                        <img src="/sp_img/about-img-2.png" alt="" className="img-fluid about__img-2" />
                        <img src="/sp_img/about-img-3.png" alt="" className="img-fluid about__img-3" />
                    </div>
                    <div className="container">
                        <div className="about__text anim-items anim__showL">
                            <div className="about__heading">
                                <div className="title_size-lg">SMART PUSH</div>
                                <p className="text_dark text_size-md">в приложениях</p>
                            </div>
                            <div className="about-item">
                                <div className="about-item__image about-item__image_blue"><img src="/sp_img/about-1.png" alt="" className="img-fluid" /></div>
                                <div className="about-item__text">НАТИВНЫЕ ПУШИ ПОД ВСЕ ГЕО</div>
                            </div>
                            <div className="about-item">
                                <div className="about-item__image about-item__image_yellow"><img src="/sp_img/about-2.png" alt="" className="img-fluid" /></div>
                                <div className="about-item__text">АВТОПУШИ ПО СОБЫТИЯМ ИНСТ/НЕ РЕГ, РЕГ/НЕ ДЕП, ДЕП/НЕ ПОВТОРНЫЙ ДЕП</div>
                            </div>
                            <div className="about-item">
                                <div className="about-item__image about-item__image_violet"><img src="/sp_img/about-3.png" alt="" className="img-fluid" /></div>
                                <div className="about-item__text">ОПОВЕЩЕНИЯ 3 РАЗА В ДЕНЬ</div>
                            </div>
                            <div className="about-item">
                                <div className="about-item__image about-item__image_white"><img src="/sp_img/about-4.png" alt="" className="img-fluid" /></div>
                                <div className="about-item__text">МОТИВИРУЮЩИЕ КАРТИНКИ И ИКОНКИ ЦЕПЛЯЮЩИЙ ТЕКСТ </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="design anim-items anim__showOpacity">
                    <div className="container">
                        <div className="title_size-md text_center text_upper design__title anim-items anim__show">СОЧНЫЙ АГРЕССИВНЫЙ ДИЗАЙН</div>
                        <p className="text_center anim-items anim__show">Дизайн приложений в Play Market подбирается для максимального конверта и быстрой модерации.
                            <br className="d-none d-lg-block" /> Категории: аркада, казино... 3+, 12+, 16+, 18+</p>
                        <div className="design__image anim-items anim__showOpacity">
                            <img src="/sp_img/design.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="work anim-items anim__showOpacity">
                    <div className="container">
                        <div className="title_size-md text_center text_upper anim-items anim__show">УДОБНЫЙ ДАШБОРД</div>
                        <div className="work__row row">
                            <div className="work-item anim-items anim__show"><img src="/sp_img/work-1.png" alt="" className="img-fluid" /></div>
                            <div className="work-item anim-items anim__show"><img src="/sp_img/work-2.png" alt="" className="img-fluid" /></div>
                            <div className="work-item anim-items anim__show"><img src="/sp_img/work-3.png" alt="" className="img-fluid" /></div>
                        </div>
                    </div>
                </div>
                <div className="partners">
                    <div className="container">
                        <div className="title_size-md text_center text_upper anim-items anim__show">Наши партнеры</div>
                        <div className="partners-group">
                            <a href="#" className="partners-item anim-items anim__show"><img src="/sp_img/partner/affhub_white.png" alt="" className="img-fluid" /></a>
                            <a href="#" className="partners-item anim-items anim__show"><img src="/sp_img/partner/logo_push_express.png" alt="" className="img-fluid" /></a>
                            <a href="#" className="partners-item anim-items anim__show"><img src="/sp_img/partner/zlodei-logo-b.png" alt="" className="img-fluid" /></a>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <footer className="footer d-flex">
                        <a href="/" className="logo footer__logo">
                            <span className="logo__text">DA <span className="text_primary">APPS</span></span>
                        </a>
                        <ul className="footer-nav d-flex">
                            {/*<li className="footer-nav__item"><a href="#" className="footer-nav__link">Бонусы</a></li>
                            <li className="footer-nav__item"><a href="#" className="footer-nav__link">Акции</a></li>*/}
                        </ul>
                        <div className="social-list d-flex align-items-center">
                            {/*<a href="#" className="social-list__link"><img src="/sp_img/youtube.png" alt="" /></a>*/}
                            <a href="https://t.me/da_apps_bot" target="_blank" rel="noopener noreferrer" className="social-list__link"><img src="/sp_img/bot.png" alt="" /></a>
                            <a href="https://t.me/da_apps_support" target="_blank" rel="noopener noreferrer" className="social-list__link"><img src="/sp_img/telegram.png" alt="" /></a>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
        </HelmetProvider>
    );
}

export default StartPage;