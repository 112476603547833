import React, { Component } from 'react';

class BasePage extends Component {
    onFieldChanged(e, arg2) {
        let target;
        let param;
        if (typeof(e)==='string' && typeof(arg2) === 'object') {
            target = arg2.target;
            param = e;
        } else {
            target = e.target;
            param = target.name;
        }
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ errors: {}, [param]: value });
    }

    getError(param) {
        if (this.state.errors && param in this.state.errors)
            return this.state.errors[param];
        return "";
    }
    renderError(fieldName) {
        delete this.uncapturedErrors[fieldName];
        return <div className="error holder"><div className="message">{this.getError(fieldName)}</div></div>;
    }
    prepareToRenderRestErrors() {
        this.uncapturedErrors = {...this.state.errors};
    }
    renderRestErrors() {
        return <div className="error holder"><div className="message">{Object.values(this.uncapturedErrors).map((err,i) => (
            <div>{(i > 0) && <br/>}{err}</div>
        ))}</div></div>;
    }

    renderPage() {
        throw new Error('renderPage should be implemented in inherited class');
    }

    render() {
        this.prepareToRenderRestErrors();
        return this.renderPage();
    }
}


export default BasePage;
