const ApplicationIcon = () => (
    <svg
        className="application-icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="6.5" y="6.5" width="3" height="3" rx="0.5" fill="#A7AFDD" />
        <rect x="6.5" y="10.5" width="3" height="3" rx="0.5" fill="#A7AFDD" />
        <rect x="6.5" y="14.5" width="3" height="3" rx="0.5" fill="#A7AFDD" />
        <rect x="10.5" y="6.5" width="3" height="3" rx="0.5" fill="#A7AFDD" />
        <rect x="10.5" y="10.5" width="3" height="3" rx="0.5" fill="#A7AFDD" />
        <rect x="10.5" y="14.5" width="3" height="3" rx="0.5" fill="#A7AFDD" />
        <rect x="14.5" y="6.5" width="3" height="3" rx="0.5" fill="#A7AFDD" />
        <rect x="14.5" y="10.5" width="3" height="3" rx="0.5" fill="#A7AFDD" />
        <rect x="14.5" y="14.5" width="3" height="3" rx="0.5" fill="#A7AFDD" />
        <circle
            cx="12"
            cy="12"
            r="10.375"
            stroke="#A7AFDD"
            strokeWidth="1.25"
        />
    </svg>
);

export default ApplicationIcon;
