import React from 'react';
import {NavLink} from "react-router-dom";

const Link = ({ children, ...props }) => (
    props.disabled ? children :
    <NavLink activeClassName="active" {...props}>
        {children}
    </NavLink>
);

export default Link;