import React, { Component } from 'react';
import axios from "axios";
import queryString from "query-string";
import {useLocation, Link} from "react-router-dom";
import Button from '../../components/UI/Button';

class RouteStats extends Component {
    state = {
        route_id: 0,
        loading: true,
        stats: []
    };

    componentDidMount() {
        //let params = queryString.parse(this.props.location.search);
        //let a = 3;
        let params = queryString.parse(this.props.location.search);
        let route_id = parseInt(params.id ? params.id : 0);
        if (route_id !== 0) {
            //console.log(`[QQQ] {componentDidUpdate} prevState.page:${prevState.page} newPage:${page}`);
            this.fetchData(route_id);
        }
    }

    async fetchData(route_id) {
        this.setState({loading: true/*, deeplinks: []*/});

        let res = await axios.post('/deeplink_stats', {route_id: route_id});

        if (res.data && Array.isArray(res.data.stats))
            this.setState({loading: false, route_id: route_id, stats: res.data.stats});
    }

    render() {
        //console.log(`[QQQ] {render} this.state.page: ${this.state.page}`,this.props.location.search);
        const {stats} = this.state;
        //console.log('[QQQ]',deeplinks[0]);
        return (
            <div className="page deeplinks deeplink-stats">
                <h1>Статистика по диплинку</h1>

                <div className="table-responsive perfect-scrollbar">
                    <table className="table">
                        <thead>
                            <tr>
                                <th colSpan='3'>
                                    Линк
                                </th>
                                <th>
                                    Количество <br/> инсталлов
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {stats.map((row,index) => (
                                <tr key={index}>
                                    <td colSpan='3' style={{wordBreak: 'break-word'}}>
                                        {row.targetLink}
                                    </td>
                                    <td>
                                        {row.count}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="footer">
                    <div/>
                    <Link to="/"><Button variant='warning' withoutRounded title='Назад' /></Link>
                    <div/>
                </div>
            </div>
        );
    }
}

export default props => <RouteStats location={useLocation()} {...props}/>;

