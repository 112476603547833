import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const ModalWindow = ({ isVisible, onClose, closeButton, anchorContainerID, children, className="" }) => {

    // Function to close the modal by clicking on the background
    const closeModal = (event) => {
        if (event.target === event.currentTarget) {
            if (onClose) onClose();
        }
    };

    // Attach a keydown event listener to close the modal on pressing the Escape key
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    const anchorContainer = document.getElementById(anchorContainerID);
    if (!isVisible || !anchorContainer) {
        return null;
    }

    return (

            ReactDOM.createPortal(
                <div className={`modal-wnd-background ${className}`}
                style={{ display: isVisible ? 'flex' : 'none' }}
                onMouseDown={closeModal}
           >
                <div className="modal-wnd-content"
                     onMouseDown={(e) => e.stopPropagation()}>
                    {closeButton && <button type='button' className="close" onClick={onClose}/>}
                    {children}
                </div>
                </div>,
                anchorContainer
            )
     
    );

};

export default ModalWindow;
