import React, { Component } from 'react';
import axios from "axios";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserGroup} from "../../context/User";
import {getResponseErrorsList, pick} from "../../util/util";
import './cabinet.css';
import Pages from "../../widgets/Pages";
import BasePage from "../base/BasePage";
import ModalWindow from "../../widgets/ModalWindow";
import Button from '../../components/UI/Button';
import EditIcon from '../../components/icons/EditIcon';
import Textarea from '../../components/UI/Textarea';
import UserSelect from '../../components/UserSelect';

class FBTokens extends BasePage {
    state = {
        loading: true,
        page: 1,
        fbTokens: null,
        addTokenFormVisible: false,
        userId: 0,
    };

    componentDidMount() {
        this.fetchData(this.state.page);
    }

    async fetchData(page, userId) {
        this.setState({loading: true});

        const sendData = { page };

        if(userId) {
            sendData.user_id = userId;
        }

        let res = await axios.post('/get_fb_tokens', sendData);

        if (res.data && Array.isArray(res.data.fbTokens))
            this.setState({loading: false, page: page, isLast: res.data.isLast, fbTokens: res.data.fbTokens, userId: userId || 0});
    }

    onAddNewToken() {
        this.showAddTokenForm();
    }
    showAddTokenForm() {
        this.setState({ addTokenFormVisible: true, fb_pixel: '', fb_token: '' });
    };
    hideAddTokenForm() {
        this.setState({ addTokenFormVisible: false });
    };
    async onAddNewTokenConfirm(e) {
        e.preventDefault();

        try {
            await axios.post('/add_fb_token', pick(this.state, ['fb_pixel','fb_token']));
            this.hideAddTokenForm();
            await this.fetchData(1);
        } catch (err) { this.setState({errors: getResponseErrorsList(err)}); }
    }

    onEditToken(fb_pixel, fb_token) {
        this.showEditTokenForm(fb_pixel, fb_token);
    }
    showEditTokenForm(fb_pixel, fb_token) {
        this.setState({ editTokenFormVisible: true, editFBPixel:fb_pixel, fb_pixel, fb_token });
    };
    hideEditTokenForm() {
        this.setState({ editTokenFormVisible: false });
    };
    async onEditTokenConfirm(e) {
        e.preventDefault();

        try {
            await axios.post('/update_fb_token', pick(this.state, ['fb_pixel','fb_token']));
            this.hideEditTokenForm();
            await this.fetchData(1);
        } catch (err) { this.setState({errors: getResponseErrorsList(err)}); }
    }

    async handlerEditDefaultToken(fb_pixel) {
        try {
            await axios.post('/set_default_fb_pixel', {fb_pixel});
            await this.fetchData(this.state.page);
        }  catch (err) {
        this.setState({errors: getResponseErrorsList(err)}); }
    }

    handleUserSelectChange = (userId) => {
        this.fetchData(1, userId);
    }

    renderPage() {
        const { loading, fbTokens, page, isLast, addTokenFormVisible, editTokenFormVisible, editFBPixel, userId } = this.state;

        const valueForField = (fieldName) => {
            return this.state[fieldName];
        };

        const modalWnd = (editMode, isVisible, anchorContainerID, onClose, onConfirm) => (
            <ModalWindow
                isVisible={isVisible}
                onClose={onClose}
                anchorContainerID={anchorContainerID}
                closeButton
            >
                <form className="" noValidate>
                    <ul>
                        <li className="field" key={"fb_pixel"}>
                            <div><label htmlFor={"fb_pixel"}>{"Пиксель:"}</label></div>
                            <div><input id={"fb_pixel"} name={"fb_pixel"} type="text" disabled={editMode}
                                        onChange={this.onFieldChanged.bind(this,"fb_pixel")}
                                        required={true} value={valueForField("fb_pixel")}/>
                                {this.renderError("fb_pixel")}
                            </div>
                        </li>
                        <li className="field" key={"fb_token"}>
                            <div><label htmlFor={"fb_token"}>Токен:</label></div>
                            <div><Textarea className={""} id={"fb_token"} name={"fb_token"} style={{height: '60px', fontSize: '13px'}}
                                        onChange={this.onFieldChanged.bind(this,"fb_token")}
                                        required={true} value={valueForField("fb_token")}/>
                                {this.renderError("fb_token")}
                            </div>
                        </li>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button withoutRounded type='submit' title={ editMode ? 'Изменить' : 'Добавить'} onClick={onConfirm}/>
                        </div>
                        {this.renderError("common")}
                    </ul>
                </form>
            </ModalWindow>
        )

        const modalAddTokenWnd = () => (
            <div className="modal-anchor" id="addTokenFormHolder">
                {modalWnd(false, addTokenFormVisible, "addTokenFormHolder",
                    this.hideAddTokenForm.bind(this), this.onAddNewTokenConfirm.bind(this))}
            </div>
        );

        const modalEditTokenWnd = (i,showWnd) => (
            <div className="modal-anchor" id={"editTokenFormHolder"+i}
                 style={{position: 'absolute', height: 0, bottom: 0, width: '100%', padding: '0'}}>
                {showWnd && modalWnd(true, editTokenFormVisible, "editTokenFormHolder"+i,
                    this.hideEditTokenForm.bind(this), this.onEditTokenConfirm.bind(this))}
            </div>
        );

        return (
            <RestrictedArea allowedTo={UserGroup.USER}>
            <div className={`page fb-tokens ${userId ? 'hide-actions' : ''}`}>
                <h1>FB pixel tokens</h1>
                <UserSelect selectedUserId={userId} setSelectedUserId={this.handleUserSelectChange}/>
                <div className='center-section' style={{maxWidth: 700, marginTop: 16}}>
                    <Button onClick={this.onAddNewToken.bind(this)} withoutRounded  title='Добавить пиксель'/>
              

                    {modalAddTokenWnd()}

                    <div className='table-responsive perfect-scrollbar' style={{marginTop: '16px'}} >
                        <table className="table">
                            <thead>
                                <tr>
                                    <th colSpan='1'>Пиксель</th>
                                    <th  colSpan='2'>Токен</th>
                                    <th>По умолчанию</th>
                                    <th colSpan='0.5'/>
                                </tr>
                            </thead>
                            <tbody>
                                {fbTokens && fbTokens.map((t,i) => (
                                    <tr key={i} style={{position: 'relative'}}>

                                        <td colSpan='1'>{t.fb_pixel}</td>
                                        <td colSpan='2' style={{minWidth: 300, wordBreak: 'break-word'}}>{t.fb_token}</td>
                                        <td style={{textAlign: 'center'}}>
                                            <input 
                                            type='checkbox'
                                            checked={t.is_default}
                                            onChange={(e) => this.handlerEditDefaultToken(t.fb_pixel)}
                                            disabled={t.is_default || !!userId}
                                            className='form-check-input'/>
                                        </td>
                                        <td colSpan='0.5'>
                                            <button type="button"
                                            onClick={this.onEditToken.bind(this, t.fb_pixel, t.fb_token)}><EditIcon/></button></td>
                                        {modalEditTokenWnd(i, editTokenFormVisible && editFBPixel === t.fb_pixel)}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="footer">
                            <Pages style={{margin: '0 auto', width: 'auto'}} handler={this.fetchData.bind(this)}
                                enabled={!loading} current={page} isLast={isLast}/>
                        </div>
                    </div>

                </div>
            </div>
            </RestrictedArea>
        );
    }
}



export default FBTokens;
