import "./iphoneSwitch.css";

const IphoneSwitch = ({ isChecked, onChange, fieldName }) => (
    <label className="iphone-switch">
        <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => onChange(fieldName, e.target.checked)}
        />
        <span className="knob" />
    </label>
);

export default IphoneSwitch;
