import { useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import usePerfectScrollbar from "../../hooks/usePerfectScrollbar";
import "./dashboard.css";

const Dashboard = () => {
    useEffect(() => {
        const theme = localStorage.getItem("theme");
        const isMenuCollapse =
            localStorage.getItem("is_menu_collapse") === "true";

        if (theme) {
            document.body.classList.add(theme);
        }

        if (isMenuCollapse) {
            document.body.classList.add("menu-collapsed");
        }

        const setFullHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.body.style.setProperty("--vh", `${vh}px`);
        };

        setFullHeight();

        window.addEventListener("resize", setFullHeight);

        return () => {
            window.removeEventListener("resize", setFullHeight);
        };
    }, []);

    usePerfectScrollbar();

    return (
        <div className="layout">
            <Sidebar />
            <div className="content-wrapper">
                <Navbar />
                <div className="page-wrapper">
                    <div className="container">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
