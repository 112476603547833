import ModalWindow from "../../widgets/ModalWindow";
import Button from "./Button";

const WarningModal = ({
    isVisible,
    anchorContainerID,
    onClose,
    text,
    onConfirmation,
}) => (
    <ModalWindow
        isVisible={isVisible}
        anchorContainerID={anchorContainerID}
        onClose={onClose}
        closeButton
        className="warning-modal"
    >
        <div className="warning-modal-content">
            <h2 dangerouslySetInnerHTML={{ __html: text }} />
            <div className="buttons-block">
                <Button
                    withoutRounded
                    title="Нет"
                    onClick={onClose}
                    variant="warning"
                />
                <Button
                    withoutRounded
                    title="Да"
                    onClick={onConfirmation}
                    variant="error"
                />
            </div>
        </div>
    </ModalWindow>
);

export default WarningModal;
