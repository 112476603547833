const RouteIcon = () => (
    <svg
        className="route-icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.7286 11.9281C18.854 12.0064 18.854 12.1889 18.7286 12.2673L15.306 14.4064C15.1728 14.4897 15 14.3939 15 14.2368L15 9.95851C15 9.80142 15.1728 9.70565 15.306 9.78891L18.7286 11.9281Z"
            fill="#A7AFDD"
        />
        <rect x="8" y="11.5" width="7" height="1.25" fill="#A7AFDD" />
        <path
            d="M15.7286 7.8304C15.854 7.90873 15.854 8.09127 15.7286 8.1696L13.106 9.80875C12.9728 9.89201 12.8 9.79624 12.8 9.63915L12.8 6.36085C12.8 6.20376 12.9728 6.10799 13.106 6.19125L15.7286 7.8304Z"
            fill="#A7AFDD"
        />
        <rect x="7" y="7.5" width="6" height="1.25" fill="#A7AFDD" />
        <path
            d="M15.7286 15.8304C15.854 15.9087 15.854 16.0913 15.7286 16.1696L13.106 17.8087C12.9728 17.892 12.8 17.7962 12.8 17.6392L12.8 14.3608C12.8 14.2038 12.9728 14.108 13.106 14.1912L15.7286 15.8304Z"
            fill="#A7AFDD"
        />
        <rect x="7" y="15.5" width="6" height="1.25" fill="#A7AFDD" />
        <circle
            cx="12"
            cy="12"
            r="10.375"
            stroke="#A7AFDD"
            strokeWidth="1.25"
        />
    </svg>
);

export default RouteIcon;
