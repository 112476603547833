const SeeIcon = () => (
    <svg
        version="1.1"
        id="Uploaded to svgrepo.com"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        fill="#000000"
    >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
            <path
                fill="black"
                d="M16,6C6,6,1,15,1,16s5,10,15,10s15-9,15-10S26,6,16,6z M16,23c-4.418,0-8-3.582-8-8s3.582-8,8-8 s8,3.582,8,8S20.418,23,16,23z M16,8c-3.866,0-7,3.134-7,7c0,3.866,3.134,7,7,7s7-3.134,7-7C23,11.134,19.866,8,16,8z M16,19 c-2.209,0-4-1.791-4-4c0-2.209,1.791-4,4-4s4,1.791,4,4C20,17.209,18.209,19,16,19z"
            ></path>
        </g>
    </svg>
);

export default SeeIcon;
