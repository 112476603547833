import "./button.css";
import Loader from "./Loader";

const Button = ({
    title,
    size,
    isDisabled,
    style = {},
    withoutRounded,
    isLoading,
    variant = "success",
    type = "button",
    onClick = () => {},
}) => (
    <button
    type={type}
    className={`custom-btn ${variant} ${size} ${
        withoutRounded ? "without-rounded" : ""
    } ${isLoading ? "is-loading" : ""}`}
    onClick={onClick}
    disabled={isDisabled || isLoading}
    style={style}
    >
        <span>{title}</span>
        {isLoading && <Loader variant={variant} type="loader-btn" />}
    </button>
);
export default Button;
