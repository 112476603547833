import { useMemo } from "react";
import CreatableSelect from "react-select/creatable";

const CreatableSelectWithLabel = ({
    label,
    value,
    onChange,
    fieldName,
    options,
    placeholder="Select.."
}) => {
    const initValue = useMemo(() => {
        if (!value) return [];

        return value.map((val) => ({ label: val, value: val }));
    }, [value]);

    return (
        <div className="filed-block">
            <label>{label}</label>
            <CreatableSelect
                isClearable
                placeholder={placeholder}
                className="select small"
                value={initValue}
                formatCreateLabel={(input) => `Добавить "${input}"`}
                onChange={(newValue) => {
                    onChange(
                        fieldName,
                        newValue.map((item) => item.value)
                    );
                }}
                options={options}
                isMulti
            />
        </div>
    );
};

export default CreatableSelectWithLabel;
